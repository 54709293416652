import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PostList from "../components/PostList"
import Categories from "../components/Categories"
import PageCover from "../components/PageCover"
import Pagination from "../components/Pagination"
import { graphql, withPrefix } from "gatsby"
import Gql from "../typings/gql"
import { POST_PER_PAGE } from "../constants"
import { AppContext } from "../utils/appContext"
import { useIntl } from "react-intl"

interface Props {
  data: Gql.CategoryPageQuery
  pageContext: { skip: number; limit: number; categoryId: string } & AppContext
}

const IndexPage: React.FC<Props> = ({
  data,
  pageContext: { skip, categories, mainMenu, mainSiteUrl },
}) => {
  const currentPage = Math.floor(skip / POST_PER_PAGE) + 1
  const pageCount = Math.ceil(data.categoryPosts.totalCount / POST_PER_PAGE)
  const intl = useIntl()
  return (
    <Layout
      categories={categories}
      mainMenu={mainMenu}
      mainSiteUrl={mainSiteUrl}
    >
      <SEO />

      <PageCover
        title={data.wordpressCategory?.acf?.display_name ?? ""}
        image={withPrefix(
          `/images/${
            data.wordpressCategory?.acf?.acf_slug
              ? `category/${data.wordpressCategory?.acf?.acf_slug}`
              : "cover-2"
          }.jpg`
        )}
      />

      <div className="main bg-gray">
        <h2 className="text-center title">
          {intl.formatMessage({ id: "common.latestnews" })}
        </h2>

        <PostList page={data.categoryPosts} />

        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          url={`/${data.wordpressCategory?.acf?.acf_slug}`}
        />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query CategoryPage($skip: Int!, $limit: Int!, $categoryId: String!) {
    wordpressCategory(id: { eq: $categoryId }) {
      id
      acf {
        acf_slug
        category_icon
        display_name
      }
    }
    categoryPosts: allWordpressPost(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
      filter: { categories: { elemMatch: { id: { eq: $categoryId } } } }
    ) {
      totalCount
      nodes {
        id
        title
        content
        slug
        excerpt
        modified
        date
        featured_media {
          source_url
        }
        categories {
          acf {
            acf_slug
            category_icon
            display_name
          }
          name
          slug
          id
        }
      }
    }
  }
`
